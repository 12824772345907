import {pluralize} from '@/lib/utils.js'
import {CartContext} from  '@/lib/cart_context.js'
import {useContext} from 'react'; 
import CartSvg from '@/components/svgs/cart_svg.js'; 

export default function SavedItems(CartState) {
  let {items, cartLink} = useContext(CartContext)
  items = items || CartState.items
  cartLink = cartLink || CartState.cartLink
  const itemCount = items?.length || 0

  return  (
      <a data-testid='saved-items-button' href={cartLink} className='lexcms-regular-button' >
        <CartSvg />
        <span> 
          ({itemCount}) Saved {pluralize(itemCount, 'Item')}
        </span>
      </a>
  )
}


