import Qs from 'qs'; 
import { parse, HTMLElement } from 'next/dist/compiled/node-html-parser'

export default function stringifySearch(search) {
  return Qs.stringify(search, {
    arrayFormat: 'brackets', 
    encode: true 
  })
}

export function loadConfig(service) {
  let page_layout_config = {}
  let stylesheet;
  let favicon; 
  let cart_enabled;
  let resource_display;
  let use_map_on_agency_locator_page;
  let inspiration_display;
  let configurator_enabled;
  try {
    page_layout_config = JSON.parse(service.config.page_layout)
  }
  catch(e){
  } 

  stylesheet = (service.config && service.config.client_stylesheet) ? service.config.client_stylesheet : null 
  favicon = (service.config && service.config.favicon) ? service.config.favicon : null 
  cart_enabled = service?.config?.cart_enabled === 'true' ? true : false ;
  use_map_on_agency_locator_page = service?.config?.use_map_on_agency_locator_page === 'true' ? true : false ;
  configurator_enabled = service?.config?.configurator_enabled === 'true' ? true : false ;

  resource_display = service?.config?.resource_display || 'list_view';
  inspiration_display = service?.config?.inspiration_display || 'application_photos_only';

  return {
    page_layout: page_layout_config,
    stylesheet: stylesheet, 
    favicon: favicon, 
    cart_enabled: cart_enabled,
    configurator_enabled: configurator_enabled,
    use_map_on_agency_locator_page: use_map_on_agency_locator_page, 
    attribute_filters_position: service?.config?.attribute_filters_position  || 'All Open',
    resource_display: resource_display,
    inspiration_display: inspiration_display
  }
}

export function getProductLink(product) {
  return `/lex-cms/product/${product.slug }`  
}

export function getGalleryLink(gallery) {
  return `/lex-cms/inspiration/application_gallery/${gallery.id}`
}

export function configuratorHost( ) {
 return process.env.NODE_ENV  == 'development' ? 'http://localhost:3003' : 'https://configurator.lighting.exchange'
}

export function is_mobile(userAgent) {
  return userAgent ? /iPad|Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(userAgent): false;
}

export function extractIdFromSlug(params, key='slug') {
  let id = null;
  if (params && params[key]) {
    let matches = params[key].match(/^(?<id>\d+)-/);
    if (matches) {
      id = matches.groups.id;
    }
  }
  return id
}

export function  downloadLink(d){ 
  let token;
  let man_id;
  let u;
  try {
    u = new URL(d.url)
    if (/^\/downloads\/m-\d+\//.test(u.pathname) ){
      token = u.pathname.split('/')[3]
      man_id = u.pathname.split('/')[2] 
    }
    else {
      token = u.pathname.split('/').pop()
    }
  }
  catch(e) {
    console.log(e)
    return ''; 
  }
  return `/lex-cms/download_referral/${man_id}/${token}/${d?.slug_name}`;
}

export function getActiveNavItem(page_layout) {
  if (page_layout?.header_html && typeof window === 'undefined'&&
    page_layout?.nav_item_selector && page_layout?.nav_active_class
  ) {
    let html = parse(page_layout?.header_html); 
    let currentPath = page_layout.currentPath 
    let navItems = html.querySelectorAll(`${page_layout.nav_item_selector}`)
    let pathSelector = `a[href="${currentPath}"]`
    if (navItems) {
      let theItem =  navItems.filter((elem) => {
        return  elem.querySelector(pathSelector);
      }
      )
      if (theItem[0]) {
        let newNode  = theItem[0];
        let classNames = Array.from(newNode.classList).join(' ');
        newNode.setAttribute('class',  classNames + ` ${page_layout?.nav_active_class }`)
        html.exchangeChild(theItem[0], newNode)
      }

    }
    return  html.toString();
  }

}



export const pluralize = (val, word, plural = word + 's') => {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
}


export const inspirationInflections =  (resource, count) => {
  let list = {
    galleries: 'gallery', 
    photos: 'photo'
  }
  if (count === 1) {
    return list[resource]
  }
  else {
    return resource
  }
}
