import Script from 'next/script'; 
import {useEffect } from 'react';
import {getActiveNavItem} from  '@/lib/utils.js';
import ConditionalWrapper from '@/components/conditional_wrapper.js'

export default function ThemeWrapper({page_layout, children}) {
  let headerHTML; 
   headerHTML = getActiveNavItem(page_layout);
   headerHTML = headerHTML ? headerHTML : page_layout?.header_html;
   if (headerHTML && page_layout?.header_html) {
    page_layout.header_html = headerHTML
   }
  return (
    <>
        <ConditionalWrapper
          condition={page_layout?.wrapper_classes || page_layout?.wrapper_id}
          wrapper={children => <div id={page_layout?.wrapper_id} 
          className={page_layout?.wrapper_classes}>{children}</div>}
        >
         {page_layout?.stylesheets?.length > 0 && 
           page_layout.stylesheets.filter((d, i) => typeof d.body !== 'undefined' ).map((d, i) => { 
             return (<style id={d.id} key={d.id ||  i}
              dangerouslySetInnerHTML={{ __html: d.body }} />)
            })
         }
          {page_layout?.scripts?.length > 0 && 
              page_layout.scripts.map((script, i) => {
                return script.src ? (
                  <Script key={i} src={script.src} id={script.id} strategy={script.load_strategy || 'beforeInteractive'}  />) : 
                  (<Script key={i} type={script.type} strategy={script.load_strategy || 'afterInteractive'}  id={script.id || i}>{`${script.body };`  } </Script>  )
              })
          }



          {headerHTML  &&
            <div dangerouslySetInnerHTML={{__html:  headerHTML}}></div>
          }
          {children}
          {page_layout?.footer_html &&
            <div dangerouslySetInnerHTML={{__html:  page_layout.footer_html}}></div>
          }
        </ConditionalWrapper>
    </>
  ); 
}

